import { TOGGLE_CK_SAVING } from '@/annotation/annotation.constants';
import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';

const ckEditorInstanceMap = {
  journalEditor: undefined as DecoupledEditor | undefined,
  reportEditor: undefined as DecoupledEditor | undefined,
  reportTemplateEditor: undefined as DecoupledEditor | undefined,
  notificationEditor: undefined as DecoupledEditor | undefined,
};

/*** If you add a new instance of ckeditor, add an instanceID to ckEditorInstanceMap and use that key as the domId ***/
export type CkEditorInstanceId = keyof typeof ckEditorInstanceMap;

/*** Set the ckEditor instance for a given CkEditorInstanceId, so it can be referenced elsewhere. ***/
export function setCKEditorInstance(
  instance: DecoupledEditor | undefined,
  editorId: CkEditorInstanceId,
): DecoupledEditor | undefined {
  exposedForTesting.ckEditorInstanceMap[editorId] = instance;
  return instance;
}

/*** Get the ckEditor instance for a given CkEditorInstanceId ***/
export function getCKEditorInstance(id: CkEditorInstanceId): DecoupledEditor | undefined {
  return exposedForTesting.ckEditorInstanceMap[id];
}

/**
 * Replaces all the html with a new html string
 *
 * @param html - the html to set
 * @param editorId - the DecoupledEditor instance to set the html on
 * @param forceUpdate? - force CK to trigger an update on setting the HTML
 */
export function setHtmlCKEditor(html: string, editorId: CkEditorInstanceId, forceUpdate?: boolean) {
  const ckeditorInstance = getCKEditorInstance(editorId);
  if (ckeditorInstance) {
    // CRAB-25207: CK triggers an update event on `setData`, and we don't want fast followers to trigger updates
    // on receiving the latest document
    !forceUpdate && ckeditorInstance.fire && ckeditorInstance.fire(TOGGLE_CK_SAVING, false);
    ckeditorInstance.setData(html ?? '');
    !forceUpdate && ckeditorInstance.fire && ckeditorInstance.fire(TOGGLE_CK_SAVING, true);
  }
}

export const exposedForTesting = {
  ckEditorInstanceMap,
};
