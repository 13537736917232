// @ts-strict-ignore
import moment from 'moment-timezone';
import HttpCodes from 'http-status-codes';
import _ from 'lodash';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  addContentError,
  debouncedImageStateChanged,
  fetchDateRange,
  fetchReport,
  iframeRefreshCountChanged,
  incrementScheduledUpdateCount,
  saveAssetSelection,
  saveContent,
  saveDateRange,
  setAssetSelection,
  setContentDisplayMetadata,
  setContentHashCode,
  setContentWarning,
  setDateRange,
  setNoCapsuleFound,
  setReportScheduleError,
  updateDateRangeStartAndEnd,
} from '@/reportEditor/report.actions';
import {
  getContent,
  getContentWidgetViewElement,
  isContentInDocument,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { setCanUseReact, setContent, setIsReact, setUseSizeFromRender } from '@/reportEditor/reportContent.actions';
import {
  AssetSelectionInputV1,
  AssetSelectionOutputV1,
  ContentInputV1,
  ContentOutputV1,
  DateRangeInputV1,
  DateRangeOutputV1,
  sqContentApi,
  sqItemsApi,
} from '@/sdk';
import { canModifyWorkbook } from '@/services/authorization.service';
import { getCurrentWorkstepId, getWorkstep } from '@/worksteps/worksteps.utilities';
import { findWorkSheetView } from '@/worksheets/worksheetView.utilities';
import { subscribe } from '@/utilities/socket.utilities';
import {
  areAssetSelectionsSimilar,
  areDateRangesSimilar,
  base64guid,
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
  isInWorkbookRouteAndWorkbookLoaded,
} from '@/utilities/utilities';
import i18next from 'i18next';
import { errorToast, warnToast } from '@/utilities/toast.utilities';
import {
  AssetSelection,
  CAPSULE_SELECTION,
  Content,
  CONTENT_LOADING_CLASS,
  ContentDisplayMetadata,
  DateRange,
  DateRangeCondition,
  DEFAULT_DATE_RANGE,
  isContentMessage,
  isDateRangeMessage,
  isReportForbiddenMessage,
  LiveScreenshotMessage,
  LiveScreenshotMessageError,
  OFFSET_DIRECTION,
  QUARTZ_CRON_PENDING_INPUT,
  REACT_JSON_VIEWS,
  REPORT_CONTENT,
  SCREENSHOT_SIZE_TO_CONTENT,
  SummaryValue,
} from '@/reportEditor/report.constants';
import { WorksheetView } from '@/worksheet/worksheet.constants';
import { sqReportStore, sqWorkbookStore } from '@/core/core.stores';
import { logWarn } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { clearContentPropertyOverrides } from './ckEditorPlugins/components/content.utilities';
import { WidgetResize } from '@ckeditor/ckeditor5-widget';
import { CONTENT_MODEL_ATTRIBUTES, ContentCallbacks, CustomPlugin } from './ckEditorPlugins/CKEditorPlugins.constants';
import { CONTENT_LOADED_EVENT } from '@/annotation/ckEditorPlugins/plugins/content/ContentResize';
import { toNumber } from '../utilities/numberHelper.utilities';
import { parseDuration, splitDuration, updateUnits } from '../datetime/dateTime.utilities';
import { DURATION_SCALAR_UNITS, GUID_REGEX_PATTERN, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { getCKEditorInstance } from '@/annotation/ckEditor.utilities';
import { AutoRate } from '@/schedule/schedule.types';
import { parseSummaryToTypeAndValue } from '@/annotation/parseSummaryToTypeAndValue';
import { Editor } from '@ckeditor/ckeditor5-core';

const NO_CAPSULE_FOUND_ERROR = /No Capsule found at index -?\d+ at 'pick'/;
const CONTENT_TYPES = [SeeqNames.Types.ImageContent, SeeqNames.Types.ReactJsonContent];
const CK_RESIZER_CLASS = 'ck-widget_with-resizer';
// N.B.: On the way "out" of the API, we don't worry about 'week's since we convert them to days before creating
// the date range.
export const SCHEDULE_REGEXES = {
  s: /^\*\/(\d+) \* \* \* \* \?$/,
  min: /^0 \*\/(\d+) \* \* \* \?$/,
  h: /^0 0 \*\/(\d+) \* \* \?$/,
  day: /^0 0 0 \*\/(\d+) \* \?$/,
  month: /^0 0 0 1 \*\/(\d+) \?$/,
  year: /^0 0 0 1 1 ? \*\/(\d+)$/,
};
export const viewRegex = new RegExp(`^\\s*http.*?/view/(${GUID_REGEX_PATTERN})\\s*$`, 'i');
// This regex handles both workbench URLs and API hrefs (the latter don't have protocol/host/port and use plural
// workbooks/worksheets)
export const worksheetRegex = new RegExp(
  `^\\s*(?:http.*)?/(?:workbooks?|(?:view|present)/worksheets?)/(${GUID_REGEX_PATTERN})/.*?(${GUID_REGEX_PATTERN}).*?\\s*$`,
  'i',
);

// Function to call to unsubscribe from the report
let reportUnsubscribeHandle: undefined | (() => void);

export function insertOrReplaceContent(contentId: string) {
  if (!isContentInDocument(contentId)) {
    // Insert
    const editor: any = getCKEditorInstance('reportEditor');
    editor.execute('insertContent', editor.model.document.selection, contentId);
  } else {
    exposedForTesting.replaceContentIfExists(contentId);
  }
}

/**
 * A soft refresh differs from a forced refresh in that it doesn't clear the cached image in the backend. If the
 * image is already cached in the backend, we will receive the already cached image and not have to wait as long
 * for the image to generate.
 *
 * @param contentId - The id of the content.
 * @param [refresh] - Options to control the loading of the content
 * @param refresh.silently - If true, replaces existing content with no spinners or progress bars
 */
function softRefreshContent(contentId: string, refresh: { silently?: boolean } = { silently: false }) {
  // Order of operations is important here. Setting the display metadata first lets the Content molecule grab it
  // to see what kind of refresh we need to do.
  setContentDisplayMetadata({ contentId, refresh });
  setContentHashCode(contentId, base64guid());
}

export function replaceContentIfExists(contentId: string, silently = false): { isNewContent: boolean } {
  const isNewContent = !isContentInDocument(contentId);
  softRefreshContent(contentId, { silently });
  return { isNewContent };
}

export function refreshAllContent(errorsOnly = false, silently = false) {
  _.forEach(sqReportStore.contentNotArchived, (content) => {
    const metadata = sqReportStore.getContentDisplayMetadataById(content.id);
    if (!errorsOnly || metadata?.errorClass) {
      softRefreshContent(content.id, { silently });
    }
  });
}

export function refreshContentUsingDate(dateRangeId: string) {
  _.forEach(sqReportStore.contentUsingDateRange(dateRangeId), (content) => {
    softRefreshContent(content.id);
  });
}

export function contentError(contentId: string | undefined, error?: string, errorCode?: number) {
  const content = sqReportStore.getContentById(contentId);
  const errorClass = sqReportStore.getDateRangeById(content?.dateRangeId)?.auto.noCapsuleFound
    ? CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR
    : CONTENT_LOADING_CLASS.ERROR;
  const displayMetadata: ContentDisplayMetadata = { contentId, errorClass, error, errorCode };
  if (content) {
    setContent({ ...content, screenshotWarning: undefined });
    setContentDisplayMetadata(displayMetadata);
    // Need to trigger a refresh of the content
    setContentHashCode(contentId, base64guid());
  }
  debouncedImageStateChanged();
  return displayMetadata;
}

export function reportScheduleError(errorCode: number, error = '') {
  setReportScheduleError(error, errorCode);
}

export function displayError(error: any) {
  if (error?.data?.statusMessage) {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
  } else {
    logWarn(formatMessage`Error generating content ${error}`);
  }
}

/**
 * Subscribes to any updates to the content in this report.
 *
 * @param {string} reportId - ID of the report
 */
export function subscribeToReport(reportId: string) {
  unsubscribeFromReport();
  reportUnsubscribeHandle = subscribe({
    channelId: [SeeqNames.Channels.LiveScreenshot, reportId],
    onMessage: handleLiveScreenshotMessage,
    onError: (error) => displayError(error),
  });
}

/**
 * Unsubscribes from updates for the current report
 */
export function unsubscribeFromReport() {
  if (reportUnsubscribeHandle) {
    reportUnsubscribeHandle();
    reportUnsubscribeHandle = undefined;
  }
}

export function handleLiveScreenshotMessageForContent(
  contentId: string,
  hashCode: string,
  warning = '',
): { isNewContent: boolean } {
  const isNewContent = !isContentInDocument(contentId);

  setContentDisplayMetadata({ contentId, refresh: {} });
  setContentWarning(contentId, warning);
  setContentHashCode(contentId, hashCode);
  return { isNewContent };
}

// Visible for testing
/**
 * Handler for subscriptions to screenshot jobs.
 *
 * @param {LiveScreenshotMessage} payload - subscription message
 */
export function handleLiveScreenshotMessage(payload: LiveScreenshotMessage): void {
  if (isContentMessage(payload)) {
    incrementScheduledUpdateCount();
    const { contentId, hashCode, warning } = payload.content;
    exposedForTesting.handleLiveScreenshotMessageForContent(contentId, hashCode, warning);
  } else if (isDateRangeMessage(payload)) {
    const { dateRangeId, start, end } = payload.dateRange;
    // Added due to the possibility of a user deleting content during the time the server spends to process it
    if (!_.isEmpty(dateRangeId)) {
      if (sqReportStore.hasIframesUsingDateRange(dateRangeId)) {
        // Iframes can be bound to date ranges and do not get content messages, so we increment the count here
        incrementScheduledUpdateCount();
      }
      updateDateRangeStartAndEnd(dateRangeId, moment.utc(start).valueOf(), moment.utc(end).valueOf());
    }
  } else if (isReportForbiddenMessage(payload)) {
    exposedForTesting.reportScheduleError(payload.status, payload.statusMessage);
  } else {
    exposedForTesting.handleAutoUpdateError(payload);
  }
}

// Visible for testing
/**
 * Handles published screenshot error messages. Adds error display to the document.
 *
 * @param error - error payload containing screenshot error status and statusMessage
 */
export function handleAutoUpdateError(error: LiveScreenshotMessageError) {
  if (
    error.status === HttpCodes.BAD_REQUEST &&
    error.statusMessage &&
    NO_CAPSULE_FOUND_ERROR.test(error.statusMessage)
  ) {
    // In rare circumstances the "no capsule found" message can arrive via the websocket channel before the
    // createContent call has finished, in which case the content will not be available in the report store yet.
    // CRAB-20172 made this more likely (temporarily, pending CRAB-24929), but this could also occur under slow /
    // congested network conditions.  For now, wait for a short time and check the report store again before failing.
    const contentId = error?.itemId?.toUpperCase();
    const noCapsuleFoundText = i18next.t('REPORT.CONTENT.NO_CAPSULE_FOUND');

    function setNoCapsuleFoundWithRetry(retries: number) {
      if (retries > 0) {
        const content = sqReportStore.getContentById(contentId);
        if (_.isNil(content)) {
          console.log(`Content ${contentId} not found in the story, retrying ${retries} more times`);
          setTimeout(() => setNoCapsuleFoundWithRetry(retries - 1), 250);
        } else {
          const dateRangeId = content.dateRangeId;
          setNoCapsuleFound(dateRangeId);
          errorExposedForTesting.contentError(contentId, noCapsuleFoundText);
        }
      }
    }

    setTimeout(() => setNoCapsuleFoundWithRetry(3), 250);
  } else if (error.status === HttpCodes.GONE) {
    if (error.itemType === SeeqNames.Types.Report && sqReportStore.isScheduleEnabled) {
      fetchReport();
    } else if (error.itemType === SeeqNames.Types.DateRange) {
      const dateRangeId = error?.statusMessage?.split(' ')[1].toUpperCase();
      const dateRange = sqReportStore.getDateRangeById(dateRangeId);
      if (dateRange?.enabled) {
        fetchDateRange(dateRangeId, true);
      }
    } else if (error.itemType && CONTENT_TYPES.includes(error.itemType)) {
      errorExposedForTesting.contentError(error.itemId, error.statusMessage, error.status);
    } else {
      errorExposedForTesting.displayError(error);
    }
  } else if (error.itemId && error.itemType) {
    const itemId = error.itemId.toUpperCase();

    if (CONTENT_TYPES.includes(error.itemType)) {
      errorExposedForTesting.contentError(itemId, error.statusMessage, error.status);
    } else if (error.itemType === SeeqNames.Types.DateRange) {
      _.forEach(sqReportStore.contentUsingDateRange(itemId), (content) =>
        errorExposedForTesting.contentError(content.id, error.statusMessage, error.status),
      );
    } else if (error.itemType === SeeqNames.Types.Report) {
      _.forEach(sqReportStore.liveOrScheduledContent, (content) =>
        errorExposedForTesting.contentError(content.id, error.statusMessage, error.status),
      );
    }
    errorExposedForTesting.displayError(error);
  } else {
    errorExposedForTesting.displayError(error);
  }
}

/**
 * Sets the properties in sqReportContentStore from a specific Seeq Content
 *
 * @param id - content ID
 * @return - A promise that resolves when the store is populated
 */
export function setStoreFromContent(id: string): Promise<void> {
  return setContent(sqReportStore.getContentById(id));
}

/**
 * Clears the cache and replaces the seeq content images with new screenshots
 *
 * @param contentIds - List of content IDs to refresh
 */
export function forceRefreshMultipleContent(contentIds: string[]) {
  _.forEach(contentIds, (contentId) => exposedForTesting.forceRefreshContent(contentId));
}

/**
 * Clears the cache and replaces all seeq content with new screenshots
 */
export function forceRefreshAllContent() {
  _.forEach(sqReportStore.contentNotArchived, (content) => exposedForTesting.forceRefreshContent(content.id));
}

/**
 * Clears the cache and replaces the seeq content images that use the specified dateRange with new screenshots based
 * on the latest values from the dateRange.
 *
 * @param dateRangeId - Id of the dateRange. If undefined, no image will be refreshed.
 */
export function forceRefreshContentUsingDate(dateRangeId: string) {
  if (_.isUndefined(dateRangeId)) {
    return;
  }
  _.forEach(sqReportStore.contentUsingDateRange(dateRangeId), (content) =>
    exposedForTesting.forceRefreshContent(content.id),
  );

  if (sqReportStore.hasIframesUsingDateRange(dateRangeId)) {
    iframeRefreshCountChanged();
  }
}

/**
 * Clears the cache and replaces the seeq content images that use the specified assetSelection with new
 * screenshots based on the latest values from the assetSelection
 *
 * @param assetSelectionId
 */
export function forceRefreshContentUsingAssetSelection(assetSelectionId: string | undefined) {
  if (_.isUndefined(assetSelectionId)) {
    return;
  }
  _.forEach(sqReportStore.contentUsingAssetSelection(assetSelectionId), (content) =>
    exposedForTesting.forceRefreshContent(content.id),
  );
  if (sqReportStore.hasIframesUsingAssetSelection(assetSelectionId)) {
    iframeRefreshCountChanged();
  }
}

/**
 * Clears the cache and replaces the seeq content image with new screenshot
 *
 * @param contentId - Content IDs to refresh
 */
export function forceRefreshContent(contentId: string) {
  sqContentApi.clearImageCache({ id: contentId }).finally(() => {
    setContentHashCode(contentId, base64guid());
    exposedForTesting.replaceContentIfExists(contentId);
  });
}

const dateRangesBeingCopied: { [key: string]: any } = {};

export function isDateRangeBeingCopied(dateRange: DateRange): boolean {
  return !!_.find(dateRangesBeingCopied, (dateRange2) => areDateRangesSimilar(dateRange, dateRange2));
}

const assetSelectionsBeingCopied: { [key: string]: any } = {};

export function isAssetSelectionBeingCopied(assetSelection: AssetSelection): boolean {
  return !!_.find(assetSelectionsBeingCopied, (assetSelection2) =>
    areAssetSelectionsSimilar(assetSelection, assetSelection2),
  );
}

/**
 * Copies a pasted content item's date range, if necessary.
 *
 * @param dateRange - the date range in question
 * @param promiseResolver - The promise resolver in use by the current component
 * @returns promise that resolves to the ID of the date range, if it exists
 */
export function copyDateRangeForPendingContent(
  dateRange: DateRange | undefined,
  promiseResolver: { resolve: any },
): Promise<string> {
  if (dateRange?.id) {
    if (dateRange.reportId !== sqReportStore.id) {
      const existingDateRange = sqReportStore.findSimilarDateRange(dateRange);
      if (_.isUndefined(existingDateRange)) {
        // Content uses dateRange which needs to be duplicated to this report
        if (dateRange.auto.enabled && _.isEmpty(dateRange.auto.cronSchedule)) {
          // We need a cron schedule so here is a default
          dateRange.auto.cronSchedule = [QUARTZ_CRON_PENDING_INPUT];
        }
        const idlessDateRange = _.omit(dateRange, ['id']);
        dateRangesBeingCopied[dateRange.id] = idlessDateRange;
        return saveDateRange(idlessDateRange).then((dateRangeId: string) => {
          delete dateRangesBeingCopied[dateRange.id];
          return dateRangeId;
        });
      } else {
        return promiseResolver.resolve(existingDateRange.id);
      }
    } else {
      // Make sure this date range is updated and in the store
      setDateRange(dateRange);
    }
  }
  return promiseResolver.resolve(dateRange?.id);
}

/**
 * Copies a pasted content item's asset selection, if necessary.
 *
 * @param assetSelection - the asset selection
 * @param promiseResolver - The promise resolver in use by the current component
 * @returns promise that resolves to the ID of the asset selection, if it exists
 */
export function copyAssetSelectionForPendingContent(
  assetSelection: AssetSelection | undefined,
  promiseResolver: { resolve: any },
): Promise<string> {
  if (assetSelection?.id) {
    if (assetSelection.reportId !== sqReportStore.id) {
      const existingAssetSelection = sqReportStore.findSimilarAssetSelection(assetSelection);
      if (_.isUndefined(existingAssetSelection)) {
        // Content uses assetSelection which needs to be duplicated to this report
        const idlessAssetSelection = _.omit(assetSelection, ['id']) as AssetSelection;
        idlessAssetSelection.name = sqReportStore.computeNonConflictingAssetSelectionName(idlessAssetSelection.name);
        assetSelectionsBeingCopied[assetSelection.id] = idlessAssetSelection;
        return saveAssetSelection(idlessAssetSelection).then((assetSelectionId: string) => {
          assetSelection.id && delete assetSelectionsBeingCopied[assetSelection.id];
          return assetSelectionId;
        });
      } else {
        return promiseResolver.resolve(existingAssetSelection.id);
      }
    } else {
      // Make sure this asset selection is updated and in the store
      setAssetSelection(assetSelection);
    }
  }
  return promiseResolver.resolve(assetSelection?.id);
}

/**
 * If a pasted content item is already in the document, is part of a different report, or uses a different date
 * range, duplicate it. If not, but it is archived, unarchive it. Otherwise, return the same content.
 *
 * @param content - content that was pasted/is pending
 * @param isContentInDocument - is the content already in the document?
 * @param [dateRangeId] - id of the date range to associate with the content
 * @param [assetSelectionId] - id of the asset selection to associate with the content
 * @param promiseResolver - The promise resolver in use by the current component
 * @returns promise that resolves to the content item we are actually adding to the document
 */
export function duplicateOrUnarchivePendingContent(
  content: Content,
  isContentInDocument: boolean,
  promiseResolver: { resolve: any },
  dateRangeId?: string,
  assetSelectionId?: string,
): Promise<Content> {
  if (
    isContentInDocument ||
    content.reportId !== sqReportStore.id ||
    content.dateRangeId !== dateRangeId ||
    content.assetSelectionId !== assetSelectionId
  ) {
    // Content needs to be duplicated before continuing
    content.dateRangeId = dateRangeId;
    content.assetSelectionId = assetSelectionId;
    return saveContent(_.omit(content, ['id']) as Content);
  }
  if (content.isArchived) {
    // Content needs to be unarchived before continuing
    return saveContent({ ...content, isArchived: false });
  }
  return promiseResolver.resolve(content);
}

/**
 * Creates the dataURL of the image. It can be used as value for a html image src
 *
 * @param img {Image} - the input image
 * @returns dataURL of the image
 */
export function getImageDataURL(img: any): string {
  const canvas = document.createElement('canvas');
  // naturalWidth&height because width and height does not work for us.
  // Using with&height results in cropped images on paste.
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;

  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0);

  return canvas.toDataURL('image/png');
}

export function canModify() {
  return (
    isInWorkbookRouteAndWorkbookLoaded() &&
    canModifyWorkbook(sqWorkbookStore) &&
    !isPresentationWorkbookMode() &&
    !isViewOnlyWorkbookMode()
  );
}

/**
 * Evaluates the properties specific to particular visualizations.
 * See worksheet.module.js for details on the useSizeFromRender optional property.
 *
 * @param workbookId - a workbook ID
 * @param worksheetId - a worksheet ID
 * @param workstepId - a workstep ID
 * @param currentOptions - The current options for a given piece of content. Depending on the difference between
 * the provided options and the evaluated options, this may reject
 * @returns - a promise that resolves when evaluation is complete and the store has been updated or rejects with
 * an untranslated error should the evaluation fail.
 */
export function evaluateVisualizationOptions(
  workbookId: string,
  worksheetId: string,
  workstepId: string,
  currentOptions?: { isReact: boolean },
): Promise<void> {
  return getWorkstep(workbookId, worksheetId, workstepId).then((response) => {
    const view = getViewFromWorkstep(response);
    const canUseReact = canBeInteractive(response);
    if (!canUseReact) {
      setIsReact(false);
    }
    const useSizeFromRender =
      !!view?.useSizeFromRender && !_.get(response, 'current.state.stores.sqTableBuilderStore.chartView.enabled');

    // We only need to check react -> not react as all visualizations support not being react.
    if (currentOptions?.isReact && !canUseReact) {
      warnToast({
        doNotTrack: true,
        messageKey: 'REPORT.CONTENT.INTERACTIVE_CANNOT_SWITCH',
      });
      setIsReact(false);
    }

    setUseSizeFromRender(useSizeFromRender);
    setCanUseReact(canUseReact);
  });
}

/**
 * Checks whether the given workstep can be interactive content
 * @param workstepResponse
 */
export function canBeInteractive(workstepResponse: object): boolean {
  const view = exposedForTesting.getViewFromWorkstep(workstepResponse);
  const hasHistogramVisualization =
    _.size(_.get(workstepResponse, 'current.state.stores.sqTrendTableStore.items')) >= 1;
  const compareMode =
    view.key === 'TREND' && _.get(workstepResponse, 'current.state.stores.sqTrendStore.isCompareMode');
  return _.includes(REACT_JSON_VIEWS, view.key) && !hasHistogramVisualization && !compareMode;
}

/**
 * Gets the view from a workstep response
 *
 * @param {Object} workstepResponse
 * @returns {Object} one of WORKSHEET_VIEWS
 */
export function getViewFromWorkstep(workstepResponse: object): WorksheetView {
  const key = _.get(workstepResponse, 'current.state.stores.sqWorksheetStore.viewKey');
  return findWorkSheetView(key);
}

export function clearPropertyOverridesForContent(contentId: string) {
  const editor: Editor = getCKEditorInstance('reportEditor') as any;
  clearContentPropertyOverrides(editor, contentId);
}

export function clearContentResize(contentId: string) {
  //region rant
  /*
  Overall CK is a very good editing system when the work you're doing is raw HTML heavy. However, the
  system was clearly not designed for managing complex React components. Because its generally well
  designed wrt customization, its totally doable, but this is one of those cases where its true focus
  rears its ugly head. Essentially, we need to keep track of the resizing in 3 places: the view, the model
  and the react component that is rendering MOST (but not all) of the view.

  In most cases, the view and the model should be close to 1:1, but due to Content being a Widget, CK
  explicitly does not update the view based on what's in the model, nor does it update the model
  based on changes in the view, but it does occasionally need to refresh the widgets's view (due to changes in
  spacing) and so refreshes the view with the classes/styles originally given to it, NOT what is in the
  model. Thankfully, CK exposes the view so we can handle this ourselves, resulting in us having to clear
  the state in the model, the state in the view, AND the state in the react component to keep everything
  in sync. Ideally we'd have a way to tell CK "if these model attributes change propagate the changes to the
  view". We partially do that with the the `editor.model.change`, as that hooks into an event defined in
  `Content.tsx::defineAttributeEvents` which fires a content specific event which `Content.molecule` is
  listening for. But we still have to listen in the content for that event, which is kind of annoying.
   */
  //endregion

  const editor: Editor = getCKEditorInstance('reportEditor');
  const contentCallbacks: ContentCallbacks = (editor.config.get(CustomPlugin.Content) as any).contentCallbacks;

  // Removes the width element from the parent div view that actually has the width style.
  editor.editing.view.change((writer) =>
    writer.removeStyle('width', getContentWidgetViewElement(getContent(contentId), editor)),
  );
  // Removes the attribute from the model
  editor.model.change((writer) =>
    writer.removeAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, contentCallbacks.getCurrentModel(contentId)),
  );
}

export function maybeClearVisualizationSpecificState(contentId: string, wasReact: boolean, isReact: boolean) {
  if (wasReact !== isReact) {
    const editor: Editor = getCKEditorInstance('reportEditor') as any;
    const contentHtmlElement = getContent(contentId);
    const widgetView = getContentWidgetViewElement(contentHtmlElement, editor);
    const widgetResizePlugin = editor.plugins.get(WidgetResize);
    const resizer = widgetResizePlugin.getResizerByViewElement(widgetView);
    if (!wasReact) {
      if (resizer) {
        // The below is necessary because the source element for the resizer doesn't exist at this point, so we
        // can't use the default `resizer.destroy()`. The below several changes are equivalent but they don't rely
        // on the source element existing.
        resizer.redraw = _.noop;
        resizer.stopListening();
        (widgetResizePlugin as any)._resizers.delete(widgetView);
        editor.editing.view.change((writer) => {
          writer.removeClass(CK_RESIZER_CLASS, widgetView);
        });
      }

      clearContentResize(contentId);
    } else {
      // The resizer occasionally draws a second resizing box on toggling, so we can rely on the destroy method to
      // delete the existing one and then manually fire an event which will create the correctly sized one.
      if (resizer) {
        resizer.destroy();
      }
      editor.model.document.fire(CONTENT_LOADED_EVENT, contentHtmlElement);
    }
  }
}

export function duplicateOrUnarchiveContent(
  content: Content,
  isContentInDocument: boolean,
  maybeDateRange?: DateRange,
  maybeAssetSelection?: AssetSelection,
): Promise<any> {
  return Promise.all([
    copyDateRangeForPendingContent(maybeDateRange, Promise),
    copyAssetSelectionForPendingContent(maybeAssetSelection, Promise),
  ])
    .then((result) => Promise.resolve([content, result[0], result[1]]))
    .then(([content, dateRangeId, assetSelectionId]) =>
      duplicateOrUnarchivePendingContent(
        content as Content,
        isContentInDocument,
        Promise,
        dateRangeId as string,
        assetSelectionId as string,
      ),
    );
}

export function duplicateContentError(contentId: string, error: any): ContentDisplayMetadata {
  // If pending content needs to be duplicated, and the current user doesn't have access to the content's
  // worksheet, then the user won't be able to finish loading the content.
  if (error.status === HttpCodes.FORBIDDEN) {
    const additionalMessage = i18next.t('REPORT.CONTENT.COULD_NOT_GENERATE');
    error.data.statusMessage = error.data.statusMessage.concat(`. ${additionalMessage}: ${contentId}`);
    addContentError(contentId);
  } else if (error?.data?.statusMessage.includes('you do not have access to the source worksheet')) {
    // TO DO: CRAB-653 Refactor this when we internationalize error messages from the backend
    // This should catch only the backend error containing this message
    // If the user does not have access to the worksheet, display custom error
    addContentError(contentId);
  }
  displayError(error);
  return contentError(contentId);
}

export const formatAssetSelectionFromApiOutput = (assetSelectionOutput: AssetSelectionOutputV1): AssetSelection => {
  if (assetSelectionOutput.asset?.isRedacted) {
    assetSelectionOutput.asset.name = i18next.t('ACCESS_CONTROL.REDACTED');
    assetSelectionOutput.asset.id = `redacted_${assetSelectionOutput.id}`;
  }
  return {
    asset: assetSelectionOutput.asset,
    name: assetSelectionOutput.name,
    id: assetSelectionOutput.id,
    isArchived: assetSelectionOutput.isArchived,
    reportId: assetSelectionOutput.report?.id,
    assetPathDepth: _.isNil(assetSelectionOutput.assetPathDepth)
      ? null
      : _.toNumber(assetSelectionOutput.assetPathDepth),
  };
};

/**
 * Extract date range parameters from API response
 *
 * @param dateRangeOutput - Output from /content endpoint call
 * @returns storeDateRange - Object in format for store
 */
export const formatDateRangeFromApiOutput = (dateRangeOutput: DateRangeOutputV1): DateRange => {
  /**
   *    ******************* IMPORTANT NOTE *******************
   *
   * This function has been ported 1:1 to sdk/pypi/seeq/spy/workbooks/_report_content_utilities.py. Do not make
   * changes here without also porting them to that function, otherwise you will impair SPy capabilities.
   */
  let formulaFormValid = false;
  const dateRange: any = {};
  _.defaultsDeep(dateRange, DEFAULT_DATE_RANGE);
  _.assign(dateRange, _.pick(dateRangeOutput, ['name', 'id', 'description']));
  _.assign(dateRange.condition, _.pick(dateRangeOutput.condition, ['name', 'id', 'isRedacted']));
  _.assign(dateRange.auto, {
    enabled: dateRangeOutput.isAutoUpdating,
  });
  dateRange.enabled = dateRangeOutput.isEnabled;
  dateRange.reportId = dateRangeOutput.report?.id;
  dateRange.isArchived = dateRangeOutput.isArchived;

  // The backend gives us back ISO8601 timestamps, but expects milliseconds back.
  if (dateRangeOutput.dateRange?.start) {
    dateRange.range.start = moment.utc(dateRangeOutput.dateRange.start).valueOf();
  }

  if (dateRangeOutput.dateRange?.end) {
    dateRange.range.end = moment.utc(dateRangeOutput.dateRange.end).valueOf();
  }

  try {
    // See .createDateRangeFormula() for expected formula formats

    // Fixed, no condition
    if (!dateRangeOutput.condition?.id && dateRangeOutput.formula?.match(/^capsule\(.*\)$/)) {
      // Nothing additional needs to be extracted from the formula
      formulaFormValid = true;
    }

    const setConditionProperties = (
      searchStart?: string,
      searchEnd?: string,
      maxDuration?: string,
      columns?: string,
      sortBy?: string,
      sortAsc?: string,
    ) => {
      dateRange.condition.range = {
        start: toNumber(searchStart),
        end: toNumber(searchEnd),
      };

      if (columns) {
        dateRange.condition.columns = columns.split(',');
        dateRange.condition.sortBy = sortBy;
        dateRange.condition.sortAsc = sortAsc === 'true';
        dateRange.condition.isCapsuleFromTable = true;
      } else {
        dateRange.condition.isCapsuleFromTable = false;
      }

      if (maxDuration) {
        const maximumDuration = splitDuration(maxDuration);
        if (!maximumDuration) {
          throw new Error(`Could not parse ${maxDuration} as a maximum duration`);
        }

        if (!_.includes(DURATION_SCALAR_UNITS, maximumDuration.units)) {
          throw new Error(
            `Invalid maximum duration unit ${maximumDuration.units} in ${maxDuration} as a maximum duration`,
          );
        }

        dateRange.condition.maximumDuration = maximumDuration;
      }
    };

    // auto enabled
    const setOffsetProperties = (offset = '1') => {
      const pick = toNumber(offset) ?? 1;
      if (pick === 1) {
        dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.CLOSEST_TO;
        dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.START;
        dateRange.condition.offset = 1;
      } else if (pick > 1) {
        dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.OFFSET_BY;
        dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.START;
        dateRange.condition.offset = pick - 1;
      } else if (pick === -1) {
        dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.CLOSEST_TO;
        dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.END;
        dateRange.condition.offset = 1;
      } else if (pick < -1) {
        dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.OFFSET_BY;
        dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.END;
        dateRange.condition.offset = Math.abs(pick) - 1;
      }
    };

    if (!dateRange.auto.enabled && !_.includes(dateRangeOutput.formula, 'Offset')) {
      // Fixed, with condition capsule selected from table
      const fixedMatchPattern =
        /^\s*\/\/ searchStart=(.*?)ms\n\s*\/\/ searchEnd=(.*?)ms\n\s*\/\/ columns=(.*?)\n\s*\/\/ sortBy=(.*?)\n\s*\/\/ sortAsc=(.*?)\n\s* capsule\(.+\)$/m;
      const fixedMatches = dateRangeOutput.formula?.match(fixedMatchPattern);
      if (dateRangeOutput.condition?.id && fixedMatches) {
        const [notUsed, parsedStart, parsedEnd, columns, sortBy, sortAsc] = fixedMatches;
        setConditionProperties(parsedStart, parsedEnd, undefined, columns, sortBy, sortAsc);
        formulaFormValid = true;
      }
    } else if (!dateRange.auto.enabled && dateRangeOutput.formula?.includes('Offset')) {
      // Fixed, with selected relative capsule
      const fixedConfigMatchPattern =
        /^\s*\/\/ searchStart=(.*?)ms\n\s*\/\/ searchEnd=(.*?)ms\n\s*\/\/ capsuleOffset=(.*?)\n\s*\/\/ maxDuration=(.*?)\n\s*capsule\(.+\)$/m;
      const fixedMatches = dateRangeOutput.formula.match(fixedConfigMatchPattern);
      if (dateRangeOutput.condition?.id && fixedMatches) {
        const [notUsed, parsedStart, parsedEnd, capsuleOffset, parsedMaxDuration] = fixedMatches;
        setConditionProperties(parsedStart, parsedEnd, parsedMaxDuration);
        setOffsetProperties(capsuleOffset);
        formulaFormValid = true;
      }
    }

    // Auto-update with condition
    const autoConditionMatchPattern =
      /^\$condition(.removeLongerThan\(.+?\))?.setCertain\(\).toGroup\(capsule\(.+\)\).pick\((-?[1-9]+[0-9]*)\)$/;
    const autoMatches = dateRangeOutput.formula?.match(autoConditionMatchPattern);
    if (dateRangeOutput.condition?.id && autoMatches) {
      // Closest to Start =>  $condition.setCertain().toGroup(capsule(start, end)).pick(1)
      // Closest to End => $condition.setCertain().toGroup(capsule(start, end)).pick(-1)
      // Offset by 1 from Start => $condition.setCertain().toGroup(capsule(start, end)).pick(2)
      // Offset by 2 from Start => $condition.setCertain().toGroup(capsule(start, end)).pick(3)
      // Offset by 1 from End => $condition.setCertain().toGroup(capsule(start, end)).pick(-2)
      const parsedPick = dateRangeOutput.formula?.match(/.*\.pick\((-?[1-9]+[0-9]*)\)$/)[1];
      const parsedMaximumDuration = dateRangeOutput.formula?.match(/removeLongerThan\((.+)\)/)?.[1];
      setConditionProperties(undefined, undefined, parsedMaximumDuration);
      setOffsetProperties(parsedPick);
      formulaFormValid = true;
    }

    // Auto-update, condition and non-condition
    if (dateRange.auto.enabled) {
      const background = dateRangeOutput.isBackground;
      const cronSchedule = dateRangeOutput.cronSchedule;
      const duration = extractDurationFromFormula(dateRangeOutput.formula);
      const offsetAndDirection = extractOffsetAndDirectionFromFormula(dateRangeOutput.formula ?? '');
      const offset = offsetAndDirection[0];
      const offsetDirection = offsetAndDirection[1];

      dateRange.auto = {
        enabled: true,
        duration,
        offset,
        offsetDirection,
        background,
        cronSchedule,
      };
    }
  } catch (e) {
    logWarn(e);
    formulaFormValid = false;
  }

  if (!formulaFormValid) {
    logWarn(`Failed to parse date range formula "${dateRangeOutput.formula}" [${dateRangeOutput.id}]`);
    dateRange.irregularFormula = dateRangeOutput.formula;
  }
  return dateRange;
};

/**
 * Extracts the date range's duration from the capsule formula.
 *
 * @param formula
 * @returns duration in milliseconds
 */
export const extractDurationFromFormula = (formula: string): number => {
  /**
   *    ******************* IMPORTANT NOTE *******************
   *
   * This function has been ported 1:1 to sdk/pypi/seeq/spy/workbooks/_report_content_utilities.py. Do not make
   * changes here without also porting them to that function, otherwise you will impair SPy capabilities.
   */
  const durationMatch = formula.match(/\$now\s*[+-]\s*(.*?)\s*[+-]\s*([\d.]+[a-z]+),/i)?.[2];
  return (parseDuration(durationMatch) as any).valueOf();
};

/**
 * Extracts the date range's offset and offset direction from the capsule formula.
 *
 * @param formula
 * @returns [{{ value: Number, units: string}}, offsetDirection: string]
 */
export const extractOffsetAndDirectionFromFormula = (formula = '') => {
  /**
   *    ******************* IMPORTANT NOTE *******************
   *
   * This function has been ported 1:1 to sdk/pypi/seeq/spy/workbooks/_report_content_utilities.py. Do not make
   * changes here without also porting them to that function, otherwise you will impair SPy capabilities.
   */
  const formulaOffset = formula.match(/[+-](.*?)[-](.*?)(\$now)(.*)/)[4];
  const value = toNumber(formulaOffset.match(/(\d+)(\w+)/)[1]);
  const units = formulaOffset.match(/(\d+)(\w+)/)[2];
  const offset = { value, units };

  const offsetDirection = formulaOffset.match(/[+-]/)[0] === '-' ? OFFSET_DIRECTION.PAST : OFFSET_DIRECTION.FUTURE;

  return [offset, offsetDirection];
};

/**
 * Various utility functions for working with cron expressions
 */
export const quartzCronExpressionHelper = () => {
  enum CRON_DAYS_OF_WEEK {
    SUN = 1,
    MON,
    TUE,
    WED,
    THU,
    FRI,
    SAT,
  }

  enum CRON_MONTHS {
    JAN = 1,
    FEB,
    MAR,
    APR,
    MAY,
    JUN,
    JUL,
    AUG,
    SEP,
    OCT,
    NOV,
    DEC,
  }

  const EVERY_DAY: CRON_DAYS_OF_WEEK[] = [
    CRON_DAYS_OF_WEEK.SUN,
    CRON_DAYS_OF_WEEK.MON,
    CRON_DAYS_OF_WEEK.TUE,
    CRON_DAYS_OF_WEEK.WED,
    CRON_DAYS_OF_WEEK.THU,
    CRON_DAYS_OF_WEEK.FRI,
    CRON_DAYS_OF_WEEK.SAT,
  ];
  const EVERY_WEEKDAY: CRON_DAYS_OF_WEEK[] = [
    CRON_DAYS_OF_WEEK.MON,
    CRON_DAYS_OF_WEEK.TUE,
    CRON_DAYS_OF_WEEK.WED,
    CRON_DAYS_OF_WEEK.THU,
    CRON_DAYS_OF_WEEK.FRI,
  ];

  interface CronData {
    seconds: string[];
    minutes: string[];
    hours: string[];
    daysOfMonth: string[];
    months: string[];
    daysOfWeek: string[];
    years?: string[];
  }

  /**
   * Parses a cron expression into an object. It has support for various special characters and will expand the
   * expression into a 'long' form (e.g. parse('1,3,5-8') returns [1,3,5,6,7,8])
   * NOTE: No validation is done, so a valid expression is expected
   */
  const parse = (expression: string): CronData => {
    const [parseSeconds, parseMin, parseHour, parseDayOfMonth, parseMonth, parseDayOfWeek, parseYear] = expression
      .trim()
      .split(' ');
    const expand = (expression: string): string[] => {
      const SPECIAL_CHARACTER_FUNCTION_MAP = {
        ',': (expression: string): string[] => expression.split(','),
        '-': (expression: string): string[] => {
          const [start, end] = expression.split('-').map((ele) => _.toInteger(ele.trim()));
          const range = [];
          for (let i = start; i <= end; ++i) {
            range.push(i);
          }
          return range.map((ele) => ele.toString());
        },
      };

      const specialCharacters = Object.keys(SPECIAL_CHARACTER_FUNCTION_MAP);
      let additionalExpressions = [];
      specialCharacters.forEach((c) => {
        if (expression.includes(c) && _.isEmpty(additionalExpressions)) {
          const more: string[] = SPECIAL_CHARACTER_FUNCTION_MAP[c](expression);
          additionalExpressions = more.reduce((prev, cur) => [...prev, ...expand(cur)], []);
        }
      });
      return _.isEmpty(additionalExpressions) ? [expression] : additionalExpressions;
    };
    const seconds = expand(parseSeconds);
    const minutes = expand(parseMin);
    const hours = expand(parseHour);
    const daysOfMonth = expand(parseDayOfMonth);
    const months = expand(parseMonth);
    const daysOfWeek = expand(parseDayOfWeek);
    const years = !_.isNil(parseYear) ? expand(parseYear) : undefined;

    return {
      seconds,
      minutes,
      hours,
      daysOfMonth,
      months,
      daysOfWeek,
      years,
    };
  };

  const createDailySchedule = (times: string[]): string => {
    return createWeeklySchedule(EVERY_DAY, times);
  };

  const createWeekdaySchedule = (times: string[]): string => {
    return createWeeklySchedule(EVERY_WEEKDAY, times);
  };

  const createWeeklySchedule = (daysOfWeek: CRON_DAYS_OF_WEEK[], times: string[]): string => {
    const { minutes, hours } = timeToCronData(times);
    const data: CronData = {
      seconds: ['0'],
      minutes,
      hours,
      daysOfMonth: ['?'],
      months: ['*'],
      daysOfWeek: daysOfWeek.map((ele) => ele.toString()),
    };

    return build(data);
  };

  const createMonthlyScheduleByDayOfMonth = (dayOfMonth: number, everyNMonth: number, times: string[]): string => {
    const { minutes, hours } = timeToCronData(times);
    const months = everyNMonth === 1 ? ['*'] : [`1/${everyNMonth}`];
    const data: CronData = {
      seconds: ['0'],
      minutes,
      hours,
      daysOfMonth: [dayOfMonth.toString()],
      months,
      daysOfWeek: ['?'],
    };

    return build(data);
  };

  const createMonthlyScheduleByDayOfWeek = (
    nth: number,
    dayOfWeek: CRON_DAYS_OF_WEEK,
    everyNMonth: number,
    times: string[],
  ): string => {
    const { minutes, hours } = timeToCronData(times);
    const months = everyNMonth === 1 ? ['*'] : [`1/${everyNMonth}`];
    const data: CronData = {
      seconds: ['0'],
      minutes,
      hours,
      daysOfMonth: ['?'],
      months,
      daysOfWeek: [`${dayOfWeek.toString()}#${nth}`],
    };

    return build(data);
  };

  const timeToCronData = (times: string[]) => {
    // Convert to number then back to string to remove padded 0's
    const timeToHoursAndMinutes = times.map((time) => time.split(':').map((ele) => _.toInteger(ele)));
    const hours = [...new Set<string>(timeToHoursAndMinutes.map(([hours, minutes]) => hours.toString()))];
    const minutes = [...new Set<string>(timeToHoursAndMinutes.map(([hours, minutes]) => minutes.toString()))];

    return {
      hours,
      minutes,
    };
  };

  const build = (data: CronData): string => {
    const {
      seconds = ['0'],
      minutes = ['*'],
      hours = ['*'],
      daysOfMonth = ['?'],
      months = ['*'],
      daysOfWeek = ['?'],
      years = undefined,
    } = data;

    const cron = `${seconds.join(',')} ${minutes.join(',')} ${hours.join(',')} ${daysOfMonth.join(',')} ${months.join(
      ',',
    )} ${daysOfWeek.join(',')}`;

    return !_.isNil(years) ? `${cron} ${years.join(',')}` : cron;
  };

  /**
   * Creates a cron expression from a rate object
   *
   * @param {{value: Number, units: string}} rate
   * @returns The cron expression to pass along to the backend
   */
  const rateToCronSchedule = (rate) => {
    let value = rate.value;
    let units = rate.units;

    // We don't need to promote units (because the UI constrains unit choices to things the backend can support),
    // except for 'weeks'.
    if (units === 'week') {
      if (value * 7 > 31) {
        ({ value, units } = updateUnits(value, 'month', units));
        value = Math.round(value);
      } else {
        value *= 7;
        units = 'day';
      }
    }

    switch (units) {
      case 's':
        return `*/${value} * * * * ?`;
      case 'min':
        return `0 */${value} * * * ?`;
      case 'h':
        return `0 0 */${value} * * ?`;
      case 'day':
        return `0 0 0 */${value} * ?`;
      case 'week':
        return `0 0 0 */${value} * ?`;
      case 'month':
        return `0 0 0 1 */${value} ?`;
      default:
        throw new Error(`Unknown unit ${units}`);
    }
  };

  /**
   * Creates a rate object from a cron schedule, or undefined if the cron schedule does not correspond to an expected
   *  number of regular units.
   */
  const cronScheduleToRate = (schedule): AutoRate | undefined => {
    return _.chain(SCHEDULE_REGEXES)
      .toPairs()
      .find(([units, regex]) => regex.exec(schedule))
      .thru((potentialPair) =>
        potentialPair
          ? {
              units: potentialPair[0],
              value: _.toInteger(potentialPair[1].exec(schedule)[1]),
            }
          : undefined,
      )
      .value();
  };

  return {
    CRON_DAYS_OF_WEEK,
    CRON_MONTHS,
    EVERY_DAY,
    EVERY_WEEKDAY,
    parse,
    createDailySchedule,
    createWeekdaySchedule,
    createWeeklySchedule,
    createMonthlyScheduleByDayOfMonth,
    createMonthlyScheduleByDayOfWeek,
    rateToCronSchedule,
    cronScheduleToRate,
  };
};

export type QuartzCronExpressionHelper = ReturnType<typeof quartzCronExpressionHelper>;

/**
 * Computes a capsule offset number based on the date variable condition
 *
 * @param condition - The date range condition
 * @returns the capsule offset
 */
export const computeCapsuleOffset = (condition: DateRangeCondition): number => {
  /**
   *    ******************* IMPORTANT NOTE *******************
   *
   * This function has been ported 1:1 to sdk/pypi/seeq/spy/workbooks/_report_content_utilities.py. Do not make
   * changes here without also porting them to that function, otherwise you will impair SPy capabilities.
   */
  const { strategy, reference, offset = 1 } = condition;
  const offsetValue = strategy === CAPSULE_SELECTION.STRATEGY.CLOSEST_TO ? 1 : toNumber(offset) + 1;
  const signValue = reference === CAPSULE_SELECTION.REFERENCE.START ? 1 : -1;
  return offsetValue * signValue;
};

/**
 * Generate a formula based on the dateRange information. Formula can be executed manually (via /formula/run
 * endpoint) or added to a dateRange definition.
 *
 * @param {Object} dateRange - dateRange information to use
 * @returns {string} executable Seeq formula
 */
export const createDateRangeFormula = (dateRange) => {
  /**
   *    ******************* IMPORTANT NOTE *******************
   *
   * This function has been ported 1:1 to sdk/pypi/seeq/spy/workbooks/_report_content_utilities.py. Do not make
   * changes here without also porting them to that function, otherwise you will impair SPy capabilities.
   */
  // Fixed range, no condition
  if (!dateRange.auto.enabled && !dateRange.condition.id) {
    return `capsule(${dateRange.range.start}ms, ${dateRange.range.end}ms)`;
  }

  const capsuleOffset = computeCapsuleOffset(dateRange.condition);
  const maximumDuration = dateRange.condition?.maximumDuration
    ? `${dateRange.condition.maximumDuration.value}${dateRange.condition.maximumDuration.units}`
    : '';

  // Fixed range, condition
  // For this configuration, we save the search range and other parameters used to find the capsule in a formula
  // comment, so that we can present it back to the user in the UI when editing the dateRange.
  if (!dateRange.auto.enabled && dateRange.condition.id && dateRange.condition.isCapsuleFromTable) {
    return `// searchStart=${dateRange.condition.range.start}ms
        // searchEnd=${dateRange.condition.range.end}ms
        // columns=${dateRange.condition.columns.join(',')}
        // sortBy=${dateRange.condition.sortBy}
        // sortAsc=${dateRange.condition.sortAsc}
        capsule(${dateRange.range.start}ms, ${dateRange.range.end}ms)`;
  } else if (!dateRange.auto.enabled && dateRange.condition.id && !dateRange.condition.isCapsuleFromTable) {
    return `// searchStart=${dateRange.condition.range.start}ms
        // searchEnd=${dateRange.condition.range.end}ms
        // capsuleOffset=${capsuleOffset}
        // maxDuration=${maximumDuration}
        capsule(${dateRange.range.start}ms, ${dateRange.range.end}ms)`;
  }

  // Auto-update range
  const sign = dateRange.auto.offsetDirection === OFFSET_DIRECTION.FUTURE ? '+' : '-';
  const offset = `${dateRange.auto.offset.value}${dateRange.auto.offset.units}`;
  const duration = `${dateRange.auto.duration}ms`;
  const rangeFormula = `capsule($now ${sign} ${offset} - ${duration}, $now ${sign} ${offset})`;

  if (dateRange.condition.id) {
    const maximumDurationSnippet = maximumDuration ? `.removeLongerThan(${maximumDuration})` : '';
    return `$condition${maximumDurationSnippet}.setCertain().toGroup(${rangeFormula}).pick(${capsuleOffset})`;
  } else {
    return rangeFormula;
  }
};

/**
 * A utility const returning a collection of filter functions that can run on an array of Elements
 */
export const filterHelpers = () => {
  const filterOutClasses = (node: Element, remove: string[]): void => {
    node.classList.remove(...remove);
    if (node.classList.length === 0) {
      node.removeAttribute('class');
    }
  };

  const filterOutAttribute = (node: Element, remove: string[]): void => {
    remove.forEach((attribute) => node.removeAttribute(attribute));
  };

  const filterPropertiesFromStyleAttribute = (node: Element, jsCssPropertiesToRemove: string[]): void => {
    if (node instanceof HTMLElement) {
      jsCssPropertiesToRemove.forEach((property) => {
        node.style[property] = null;
      });
      // If we've filtered out every property in the style attribute, remove it altogether
      if (!node.style.cssText) filterOutAttribute(node, ['style']);
    }
  };

  const orderPropertiesFromStyleAttributeAlphabetically = (node: Element): void => {
    if (node instanceof HTMLElement) {
      const sortedProperties = node.style.cssText
        .split(/;\s*/)
        .filter((property) => !!property)
        .sort();
      if (sortedProperties.length === 0) return;
      node.style.cssText = `${sortedProperties.join('; ')};`;
    }
  };

  const filterOutImageLoadingClasses = (node: Element): void => {
    filterOutClasses(node, [
      CONTENT_LOADING_CLASS.LOADED,
      CONTENT_LOADING_CLASS.ERROR,
      CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR,
      CONTENT_LOADING_CLASS.SPINNER,
    ]);
  };

  const applyTransform = (nodes: Element[], selector: string, transform: (node: Element) => void): void => {
    nodes.forEach((node) => {
      if (node.innerHTML === selector) {
        transform(node);
      }
      const children = node.querySelectorAll(selector);
      for (let i = 0; i < children.length; ++i) {
        transform(children[i]);
      }
    });
  };

  return {
    filterOutClasses,
    filterOutAttribute,
    filterPropertiesFromStyleAttribute,
    orderPropertiesFromStyleAttributeAlphabetically,
    filterOutImageLoadingClasses,
    applyTransform,
  };
};

/**
 * Parses HTML to nodes
 *
 * @param {string} document - html document
 * @returns {Element[]} array of nodes created from the provided HTML string
 */
export const parseHtmlToNodes = (htmlDocument: string): Element[] => {
  if (!htmlDocument) return [];
  // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
  const ownerDocument = document.implementation.createHTMLDocument('virtual');
  ownerDocument.body.innerHTML = htmlDocument;

  return [...ownerDocument.body.children];
};

/**
 * Parses HTML to nodes while removing attributes that are irrelevant for comparing or saving documents
 *
 * @param {string} document - html document
 * @return {Element[]} array of Elements
 */
export const filterOutCommonAttributesForCompareOrSave = (document: string | Element[]): Element[] => {
  const docAsNodes = typeof document === 'string' ? parseHtmlToNodes(document) || [] : document || [];
  const filter = filterHelpers();
  filter.applyTransform(docAsNodes, 'img', (node) => {
    filter.filterOutImageLoadingClasses(node);
    filter.filterPropertiesFromStyleAttribute(node, ['minWidth', 'maxWidth', 'minHeight', 'maxHeight']);
    filter.orderPropertiesFromStyleAttributeAlphabetically(node);
  });
  filter.applyTransform(docAsNodes, 'a', (node) => {
    filter.filterOutAttribute(node, ['rel']);
  });
  return docAsNodes;
};

/**
 * Parses HTML to nodes while removing attributes that are irrelevant for comparing documents
 *
 * @param {string} document - html document
 * @return {Element[]} array of Elements
 */
export const parseHtmlToNodesForComparison = (document: string): Element[] => {
  const filterOutSrc = (node: Element): void => {
    node.removeAttribute('src');
  };
  const filteredDocAsNodes = filterOutCommonAttributesForCompareOrSave(document);
  const filter = filterHelpers();
  filter.applyTransform(filteredDocAsNodes, `img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`, (node) => {
    filterOutSrc(node);
  });

  return filteredDocAsNodes;
};

/**
 * Compares two documents for equality
 *
 * @param {string} document1 - first document
 * @param {string} document2 - second document
 */
export const areDocumentsEqual = (document1: string, document2: string): boolean => {
  if (document1 === document2) return true;
  const strippedPrevDocAsNodes = parseHtmlToNodesForComparison(document1) || [];
  const strippedNewDocAsNodes = parseHtmlToNodesForComparison(document2) || [];

  return areNodesEqual(strippedPrevDocAsNodes, strippedNewDocAsNodes);
};

/**
 * Compares two lists of nodes for equality
 *
 * @param {Element[]} nodes1 - first set of nodes
 * @param {Element[]} nodes2 - second set of nodes
 */
export const areNodesEqual = (nodes1: Element[], nodes2: Element[]): boolean => {
  if (nodes1.length !== nodes2.length) {
    return false;
  }

  let isEqual = true;
  for (let i = 0; i < nodes1.length && isEqual; ++i) {
    isEqual = nodes1[i].isEqualNode(nodes2[i]);
  }
  return isEqual;
};

/**
 * Strips the document of front end related classes that aren't applicable for API users. Also protects against
 * an incomplete document caused by transitional states on the front end
 *
 * @param {string} document - html document
 * @returns {string} updated document
 */
export const getStrippedAndValidatedDocument = (document: string): string => {
  const replaceSeeqImageSourceWithBaseUrl = (node: Element) => {
    const contentId = node.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent);
    const contentImageUrlNoFragment = sqReportStore.getContentImageUrl(contentId).replace(/\?.*/, '');
    node.setAttribute('src', contentImageUrlNoFragment);
  };
  // Note: The order of filtering matters since each step has side effects
  const docAsNodes = parseHtmlToNodes(document) || [];
  const filter = filterHelpers();
  filter.applyTransform(docAsNodes, `img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`, (node) => {
    replaceSeeqImageSourceWithBaseUrl(node);
  });
  filterOutCommonAttributesForCompareOrSave(docAsNodes);

  return docAsNodes.map((ele) => ele.outerHTML).join('');
};

/**
 * Iterates over the DOM and calls content/id/sourceUrl for each piece of content within the DOM and replaces the
 * current href with the full url returned from the API call.
 *
 * @returns a promise that resolves when all content URLs are replaced with the full URL
 */
export const setAllContentUrlsToFullUrls = () => {
  const ID_FROM_SOURCE_URL_REGEX = /\/api\/content\/(.+?)\/sourceUrl$/;
  const $links = _.map(getAllContent(), (node) => {
    return node.parentElement.closest('a');
  });

  if ($links.length === 0) {
    return Promise.resolve();
  }

  return sqContentApi.getContentsWithAllMetadata({ reportId: sqReportStore.id }).then((response) => {
    const idToContent = _.keyBy(response?.data?.contentItems, 'id');
    $links.forEach((element) => {
      const contentUrl = element.getAttribute('href');
      const id = contentUrl?.match(ID_FROM_SOURCE_URL_REGEX)?.[1];
      const sourceUrl = idToContent?.[id]?.sourceUrl;
      if (!_.isNil(sourceUrl)) {
        element.setAttribute('href', sourceUrl);
      } else {
        logWarn(`Could not match sourceUrl for element [href=${contentUrl}] with parsed id: ${id}`);
      }
    });
  });
};

/**
 * Construct a DateRangeInputV1 object from the frontend dateRange
 *
 * @param {DateRange} dateRange
 * @returns {DateRangeInputV1}
 */
export const formatDateRangeToApiInput = (dateRange) => {
  const dateRangeInput: DateRangeInputV1 = _.pick(dateRange, ['name', 'description', 'formula', 'updatePeriod']);
  dateRangeInput.background = false;
  dateRangeInput.enabled = true; // Always enabled if saving
  // Only add sqReportStore.reportId if this is a new date range (CRAB-24551)
  dateRangeInput.reportId = dateRange.id ? dateRange.reportId : sqReportStore.id;
  dateRangeInput.formula = createDateRangeFormula(dateRange);
  dateRangeInput.conditionId = dateRange.condition?.id;
  dateRangeInput.archived = dateRange.isArchived;

  if (dateRange.auto.enabled) {
    dateRangeInput.background = dateRange.auto.background;
    dateRangeInput.cronSchedule = dateRange.auto.cronSchedule;
  }

  return dateRangeInput;
};

/**
 * Determines if the topic document can be modified based on the current user, document, and view mode.
 *
 * @returns {boolean} true if it can be modified, false otherwise
 */
export const canModifyDocument = () => {
  return (
    isInWorkbookRouteAndWorkbookLoaded() &&
    canModifyWorkbook(sqWorkbookStore) &&
    !isPresentationWorkbookMode() &&
    !isViewOnlyWorkbookMode()
  );
};

/**
 * Tells appserver the current report is being looked at
 */
export const postReportViewed = () => {
  try {
    sqItemsApi.setProperty(
      {
        value: moment.utc().valueOf() * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
        unitOfMeasure: 'ns',
      },
      {
        id: sqReportStore.id,
        propertyName: SeeqNames.Properties.LastViewedAt,
      },
      { ignoreLoadingBar: true },
    );
  } catch (_error) {
    // If we can't post it's likely due to network problems, so just noop.
    _.noop();
  }
};

/**
 * Returns a jQuery object of all pieces of Seeq content, regardless of their state.
 *
 * @returns {jQuery} object of all Seeq content elements
 */
export const getAllContent = (): NodeListOf<Element> => {
  return document.querySelectorAll(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`);
};

/**
 * Extract content parameters from API response.
 *
 * @param {ContentOutputV1} contentOutput - Output of /content endpoint call
 * @returns {Object} storeContent - Object in format for store
 */
export const formatContentFromApiOutput = (contentOutput: ContentOutputV1): Content => {
  const content: any = _.pick(contentOutput, ['name', 'id', 'height', 'width', 'scale', 'timezone', 'hashCode']);
  content.workbookId = contentOutput.sourceWorkbook;
  content.worksheetId = contentOutput.sourceWorksheet;
  content.workstepId = contentOutput.sourceWorkstep;
  content.useSizeFromRender = !!contentOutput.selector;
  content.dateRangeId = contentOutput.dateRange?.id;
  content.reportId = contentOutput.report?.id;
  content.isArchived = contentOutput.isArchived;
  content.isReact = contentOutput.react;
  content.screenshotWarning = contentOutput.warning;
  if (contentOutput.summaryType) {
    content.summary = {
      ..._.find(REPORT_CONTENT.SUMMARY, { key: contentOutput.summaryType }),
    };
    content.summary.value = convertSummaryValueBasedOnType(content.summary.key, contentOutput.summaryValue);
  }
  content.assetSelectionId = contentOutput.assetSelection ? contentOutput.assetSelection.id : undefined;

  return content;
};

/**
 * Returns all of the content Ids in document order
 *
 * @return the list of content ids
 */
export const getContentIdsInDocumentOrder = () => {
  return _.map(getAllContent(), (content) => content.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent));
};

/**
 * Converts the given summary type and value into a SummaryValue
 *
 * @param type - The type of the summary given by the backend
 * @param value - The value of the summary given by the backend
 * @return - The frontend representation of the SummaryValue
 */
export const convertSummaryValueBasedOnType = (type: SummaryTypeEnum, value: string): SummaryValue => {
  if (SummaryTypeEnum.NONE === type) {
    return undefined;
  }
  return type === SummaryTypeEnum.DISCRETE ? splitDuration(value) : Number(value);
};

/**
 * Returns all of the content Ids currently in the user's highlight/selection
 *
 * @return the list of selected content ids
 */
export const getContentIdsInSelection = (): string[] => {
  const selection = window.getSelection();
  if (selection?.rangeCount === 0) {
    return [];
  }

  return _.chain(
    selection?.getRangeAt(0).cloneContents().querySelectorAll(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`),
  )
    .map((content) => content.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent) as string)
    .filter((content) => !_.isEmpty(content))
    .value();
};

/**
 * Extracts the workthing parameters from a content URL. Can handle normal worksheet URLs, presentation URLs, and
 * view-only URLs.
 *
 * @param {String} url - The url from which to extract the parameters.
 * @returns {Promise} A promise that resolves with the workbookId, worksheetId, and workstepId. If URL is not a valid
 *   link, rejects promise with a translation key suitable for display
 */
export const getWorksheetUrlParams = (url: string) => {
  let workbookId, worksheetId;
  if (viewRegex.test(url)) {
    [, worksheetId] = url.match(viewRegex);
    return sqItemsApi.getItemAndAllProperties({ id: worksheetId }).then(({ data: { workbookId } }) =>
      getCurrentWorkstep(workbookId, worksheetId).then((workstepId) => ({
        workbookId,
        worksheetId,
        workstepId,
      })),
    );
  } else if (worksheetRegex.test(url)) {
    [, workbookId, worksheetId] = url.match(worksheetRegex);
    return getCurrentWorkstep(workbookId, worksheetId).then((workstepId) => ({
      workbookId,
      worksheetId,
      workstepId,
    }));
  } else {
    return Promise.reject('REPORT.CONTENT.LINK_INVALID');
  }
};

export const getCurrentWorkstep = (workbookId: string, worksheetId: string) => {
  return sqItemsApi
    .getItemAndAllProperties({ id: workbookId })
    .then(({ data: item }) => {
      if (item.type === SeeqNames.Types.Topic) {
        return Promise.reject('REPORT.CONTENT.LINK_TOPIC_DOCUMENT_NOT_ALLOWED');
      }
    })
    .then(() => getCurrentWorkstepId(workbookId, worksheetId));
};

/**
 * Determines if a URL is a valid seeq content URL that can be used to create an image.
 *
 * @param {String} url - The URL to test.
 * @returns {Boolean} True if it a seeq content URL, false otherwise
 */
export const isWorksheetUrl = (url) => {
  return viewRegex.test(url) || worksheetRegex.test(url);
};

export const formatAssetSelectionToApiInput = (assetSelection: AssetSelection): AssetSelectionInputV1 => {
  return {
    reportId: assetSelection.id ? assetSelection.reportId : sqReportStore.id,
    name: assetSelection.name,
    assetId: assetSelection.asset.id,
    selectionId: assetSelection.id ? assetSelection.id : null,
    archived: assetSelection.isArchived,
    assetPathDepth: assetSelection.assetPathDepth,
  };
};

/**
 * Construct a ContentInputV1 object from the frontend content
 *
 * @param {Object} content
 * @returns {ContentInputV1}
 */
export const formatContentToApiInput = (content) => {
  // TODO CRAB-20427 - For now, we do not persist the content timezone at all since the user has no way
  // to set the timezone via the UI.  There were certain circumstances where the content would be persisted
  // with a timezone (e.g. restoring content) or removed (e..g creating/modifying content, CRAB-20426), leading
  // to inconsistent renders since the content timezone has the highest priority.
  // The behavior that we want for scheduled docs is for the report timezone to have priority with a fallback
  // on the worksheet timezone. Therefore, never persist a content timezone until we work on CRAB-20427. -Che & Mike
  const contentInput: ContentInputV1 = _.pick(content, [
    'name',
    'height',
    'width',
    'scale',
    'worksheetId',
    'workstepId',
    'dateRangeId',
    'summaryType',
    'summaryValue',
    'assetSelectionId',
  ]);
  contentInput.selector = content.useSizeFromRender ? SCREENSHOT_SIZE_TO_CONTENT.SELECTOR : undefined;
  contentInput.reportId = sqReportStore.id;
  contentInput.archived = content.isArchived;
  contentInput.react = content.isReact;
  _.assign(contentInput, parseSummaryToTypeAndValue(content.summary));
  return contentInput;
};

export const exposedForTesting = {
  replaceContentIfExists,
  forceRefreshContent,
  insertOrReplaceContent,
  handleLiveScreenshotMessageForContent,
  reportScheduleError,
  handleAutoUpdateError,
  getViewFromWorkstep,
};

export const errorExposedForTesting = {
  contentError,
  displayError,
};
